import { FilterPopover } from 'components/filter';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

import { AddressFilter } from './address-filters';

type AddressFilterPopoverProps = {
  tableFilters: UseTableFiltersType;
};
export const AddressFilterPopover = ({ tableFilters }: AddressFilterPopoverProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { country, has_postal_code, has_city, has_country, has_county, has_state, address_not_empty } = tableFilters;
  const hasFilter =
    country !== undefined ||
    has_postal_code !== undefined ||
    has_city !== undefined ||
    has_country !== undefined ||
    has_county !== undefined ||
    has_state !== undefined ||
    address_not_empty !== undefined;

  return (
    <FilterPopover hasFilter={hasFilter} open={open} onOpenChange={({ open }) => setOpen(open)}>
      <AddressFilter
        filters={tableFilters}
        onClose={() => {
          setOpen(false);
        }}
      />
    </FilterPopover>
  );
};
