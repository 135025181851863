import { HStack, IconButton, Table, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ADDRESS_STATE_KEY } from 'apis/addressses';
import { updateAddress } from 'apis/transaction-api';
import AppHeader from 'app/app-header';
import { AIIcon } from 'components/icons';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { Button } from 'components/ui/button';
import TableContainer from 'components/ui/table-container';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';
import { Address, AddressInstance, AddressSuggestionWithAddressInstance } from 'types/shared-types';

import { AddressFilterPopover } from './address-popover';
import { ResubmitAddressModal } from './resubmit-address-modal';
import { InvalidAddressesTableRow } from './table-row';

type InvalidAddressTableProps = {
  addresses: AddressSuggestionWithAddressInstance[];
  tableFilters: UseTableFiltersType;
  size: number;
  isLoading: boolean;
};

export const InvalidAddressTable = ({ addresses, tableFilters, size, isLoading }: InvalidAddressTableProps) => {
  const { orgId } = useOrg();
  const { open, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const [selectedIndex, setSelectedIndex] = useState<Record<string, number>>({});

  const { mutate: doUpdateAddress, isPending } = useMutation({
    mutationFn: (payload: any) => {
      return updateAddress(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADDRESS_STATE_KEY] });
      handleSuccessNotification('Addresses have been validated successfully.');
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const handleSelectChange = (addressId: string, selectedIndex: number) => {
    setSelectedIndex(prev => ({ ...prev, [addressId]: selectedIndex }));
  };

  const handleUpdateNowClick = () => {
    if (!addresses) {
      return;
    }

    const payload = addresses
      .map((row, index: number) => {
        if (addresses[index].suggestions.length > 0) {
          const defaultValue = {
            city: addresses[index].suggestions[0].city,
            county: addresses[index].suggestions[0].county,
            state: addresses[index].suggestions[0].state,
            postal_code: addresses[index].suggestions[0].postal_code,
            country: addresses[index].suggestions[0].country,
          };
          const { phone, street_1, street_2, county, ...rest } = (row.suggestions[selectedIndex[row.id]] ||
            defaultValue) as Address;
          return {
            phone: phone || row.address.phone,
            street_1: street_1 || row.address.street_1,
            street_2: street_2 || row.address.street_2,
            county: county || row.address.county,
            type: row.address.type,
            id: row.id,
            transaction_id: row.address.transaction_id,
            ...rest,
          } as AddressInstance;
        }
      })
      .filter(value => !!value);
    doUpdateAddress(payload);
  };

  const handleUpdateAddress = ({ suggestions, address, id }: AddressSuggestionWithAddressInstance) => {
    let defaultValue = {};
    if (suggestions.length > 0) {
      defaultValue = {
        city: suggestions[0].city,
        county: suggestions[0].county,
        state: suggestions[0].state,
        postal_code: suggestions[0].postal_code,
        country: suggestions[0].country,
      };
    }

    const { phone, street_1, street_2, county, ...rest } = (suggestions[selectedIndex[id]] || defaultValue) as Address;

    doUpdateAddress([
      {
        id,
        phone: phone || address.phone,
        street_1: street_1 || address.street_1,
        street_2: street_2 || address.street_2,
        county: county || address.county,
        type: address.type,
        transaction_id: address.transaction_id,
        ...rest,
      },
    ]);
  };

  const isDataEmpty = addresses.length === 0;

  return (
    <>
      <HStack gap={1} justifyContent={'space-between'}>
        <AppHeader />
        {!isDataEmpty && (
          <HStack>
            <AddressFilterPopover tableFilters={tableFilters} />
            <IconButton
              aria-label={'Revalidate Address using kintsugi intelligence'}
              mr={1}
              variant={'transparent-with-icon'}
              onClick={onOpen}
            >
              <AIIcon size="lg" />
            </IconButton>
          </HStack>
        )}
      </HStack>

      {isDataEmpty && !isLoading ? (
        <TableEmptyState tableName={'Addresses'} />
      ) : (
        <>
          <HStack gap={3}>
            <Text fontSize="medium" fontWeight="medium">
              Bulk Update addresses below with the suggested address?
            </Text>
            <Button size="xs" colorPalette="blue" loading={isPending} width={'100px'} onClick={handleUpdateNowClick}>
              Update
            </Button>
          </HStack>
          <TableContainer>
            <Table.Root>
              <Table.Header>
                <Table.Row>
                  {['CURRENT ADDRESS', 'SUGGESTED ADDRESS'].map((caption, idx) => (
                    <Table.ColumnHeader key={idx}>
                      <Text fontSize="xs" fontWeight="bold">
                        {caption}
                      </Text>
                    </Table.ColumnHeader>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {addresses.map(address => (
                  <InvalidAddressesTableRow
                    key={address.id}
                    {...address}
                    handleUpdateAddress={handleUpdateAddress}
                    onSelectChange={handleSelectChange}
                  />
                ))}
                {isLoading && <TableRowSkeleton length={size} columns={['Current Address', 'Suggested Address']} />}
              </Table.Body>
            </Table.Root>
          </TableContainer>
        </>
      )}

      {open && <ResubmitAddressModal isOpen={open} onClose={onClose} />}
    </>
  );
};
